<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterProducts"
      @clear="clearFilter"
      currentComponent="products-filter"
    />

    <Table :data="dataTable">
      <template slot="title-action">
        <v-btn
          @click="showBigImages = !showBigImages"
          class="mr-2"
          x-large
          color="primary"
          icon
        >
          <v-icon size="20">
            {{
              showBigImages
                ? 'fas fa-compress-arrows-alt'
                : 'fas fa-expand-arrows-alt'
            }}
          </v-icon>
        </v-btn>
      </template>

      <template slot="new-btn">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="mx-2"
              color="primary"
              rounded
            >
              Novo
              <v-icon right>fas fa-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-list-item @click="actionNew">
                <v-list-item-title>
                  <v-icon
                    small
                    color="primary"
                  >
                    fas fa-people-carry
                  </v-icon>
                  Fornecido
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="actionNewComposed">
                <v-list-item-title>
                  <v-icon
                    small
                    color="primary"
                  >
                    fas fa-puzzle-piece
                  </v-icon>
                  Composto
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="actionNewTemporary">
                <v-list-item-title>
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-clock
                  </v-icon>
                  Temporário
                </v-list-item-title>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:tbody="{ item }">
        <td>
          <ImageAvatar
            defaultIcon="fas fa-box"
            :bigImage="showBigImages"
            :img_src="item.product_image"
          />
        </td>
        <td>
          <ul class="pa-0">
            <li>{{ item.product_name }}</li>
            <li>{{ item.product_reference }}</li>
          </ul>
        </td>
        <td>
          <ul class="pa-0">
            <li>{{ item.vendor_trading_name }}</li>
            <li>{{ item.vendor_company_name }}</li>
            <li>Cód: {{ item.code_fornecedor }}</li>
          </ul>
        </td>
        <td class="text-right">
          <div v-if="item.vendors && item.vendors.is_fixed">
            {{ item.vendors.cost | formatCost }}
          </div>
          <div v-else-if="item.is_composition">
            <v-icon
              small
              color="primary"
              left
            >
              fas fa-puzzle-piece
            </v-icon>
            <span>Composição</span>
          </div>
          <div v-else>
            <v-icon
              small
              color="primary"
              left
            >
              fas fa-table
            </v-icon>
            <span>Tabela</span>
          </div>
        </td>
        <td class="d-flex justify-center align-center" style="gap: 10px;">
          <div>{{ item.vendor_date }}</div>
          <StatusChip
            :status="vendorStatus"
            :currentStatus="item.vendor_status"
          />
        </td>
        <td class="text-center">{{ item.factor_cost }}</td>
        <td class="text-center">
          <StatusChip :currentStatus="item.status" />
        </td>
      </template>
    </Table>

    <v-dialog
      max-width="600"
      v-model="temporaryFormModal"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span>Produto temporário</span>
          <v-spacer />
          <v-btn
            @click="temporaryFormModal = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <TemporaryProductsForm
            @success="tempCreated"
            @error="tempCreationFailed"
            ref="temporaryForm"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            @click="createTemporary"
            color="primary"
            rounded
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import TemporaryProductsForm from './TemporaryProductsForm'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    ImageAvatar,
    Table,
    StatusChip,
    FilterDrawer,
    TemporaryProductsForm,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    temporaryFormModal: false,
    showBigImages: false,
    vendorStatus: [
      { value: 1, text: 'Ok', color: 'success' },
      { value: 2, text: 'Consulta', color: 'cyan' },
      { value: 3, text: 'Sem estoque', color: 'error' },
      { value: 4, text: 'Fora de linha', color: 'grey' },
    ],
    dataTable: {
      title: 'Produtos',
      new: 'products-new',
      edit: 'products-edit',
      context: 'products-context',
      massAction: 'products-mass-action',
      headerButtons: {
        filter: true,
        new: false,
        newMenu: true,
        expandRow: true,
      },
      items: [],
      isLoading: true,
      headers: [
        {
          text: 'Imagem',
          value: 'product_image',
          align: 'center',
          sortable: false,
          width: 50,
        },
        { text: 'Nome', value: 'product_name' },
        { text: 'Fornecedor', value: 'product_vendor_name' },
        { text: 'Custo Fixo', value: 'product_fixed_cost', align: 'end' },
        { text: 'Status Fornecedor', value: 'vendor_status', align: 'center' },
        { text: 'Tabela Fator', value: 'factor_cost', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
    },
  }),
  filters: {
    formatCost(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
  methods: {
    getProducts() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('products')
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterProducts(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('products/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getProducts()
    },
    actionNew() {
      this.$router.push({
        name: this.dataTable.new,
        params: { isComposed: false },
      })
    },
    actionNewComposed() {
      this.$router.push({
        name: this.dataTable.new,
        params: { isComposed: true },
      })
    },
    actionNewTemporary() {
      this.temporaryFormModal = true
    },
    createTemporary() {
      this.$refs.temporaryForm.createTemporary()
    },
    tempCreated() {
      this.$store.commit('setModal', {
        show: true,
        component: 'success',
        text: 'Produto temporário cadastrado com sucesso',
        confirm: () => {
          this.$router.push({ name: 'products' })
          this.temporaryFormModal = false
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    tempCreationFailed() {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Erro ao cadastrar produto temporário',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
  mounted() {
    this.getProducts()

    contextMenu.$once('action', (type) => {
      if (type === 'products') {
        this.getProducts()
      }
    })
    massAction.$once('action', () => {
      this.getProducts()
    })
  },
}
</script>
